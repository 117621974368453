import sessionService from '../../../services/session';

const { counterID } = require('utils/yandexMetric');

const userId = sessionService.getResourceId() || undefined;

export default func =>
    (...data) => {
        const { event, ...analytic } = func(...data);

        if (window && window.ym) {
            window.ym(counterID, 'params', {
                event_name: event,
                ...analytic,
                user_id: userId,
            });
        }
    };
